<template>
  <div class="pages">
    <h1>Editar Pacto Global</h1>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <p v-if="loading">Carregando dados...</p>
    <div class="wrapper" v-if="!loading">      
      <div>
        <label>Comitê Gestor</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_manager"
              autofocus
              holder-id="codex-editor-content-manager"
              save-button-id="save-button-manager"
              :init-data="initDataManager"
              @save="saveManager"
              @ready="onReadyManager"
              @change="onChangeManager"
          />
        </div>
      </div>
      <div>
        <label>Titulares</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_holders"
              autofocus
              holder-id="codex-editor-content-holders"
              save-button-id="save-button-holders"
              :init-data="initDataHolders"
              @save="saveHolders"
              @ready="onReadyHolders"
              @change="onChangeHolders"
          />
        </div>
      </div>
      <div>
        <label>Suplentes</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_alternate"
              autofocus
              holder-id="codex-editor-content-alternate"
              save-button-id="save-button-alternate"
              :init-data="initDataAlternate"
              @save="saveAlternate"
              @ready="onReadyAlternate"
              @change="onChangeAlternate"
          />
        </div>
      </div>
      <div>
        <label>Data de Criação</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_creation_date"
              autofocus
              holder-id="codex-editor-content-creation-date"
              save-button-id="save-button-creation-date"
              :init-data="initDataCreationDate"
              @save="saveCreationDate"
              @ready="onReadyCreationDate"
              @change="onChangeCreationDate"
          />
        </div>
      </div>
      <div>
        <label>Sobre o Pacto Global</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_about"
              autofocus
              holder-id="codex-editor-content-about"
              save-button-id="save-button-about"
              :init-data="initDataAbout"
              @save="saveAbout"
              @ready="onReadyAbout"
              @change="onChangeAbout"
          />
        </div>
      </div>
      <div>
        <label>Objetivos</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_goals"
              autofocus
              holder-id="codex-editor-content-goals"
              save-button-id="save-button-goals"
              :init-data="initDataGoals"
              @save="saveGoals"
              @ready="onReadyGoals"
              @change="onChangeGoals"
          />
        </div>
      </div>
      <div>
        <label>Atividades</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_activities"
              autofocus
              holder-id="codex-editor-content-activities"
              save-button-id="save-button-activities"
              :init-data="initDataActivities"
              @save="saveActivities"
              @ready="onReadyActivities"
              @change="onChangeActivities"
          />
        </div>
      </div>
      <div>
        <label>Informações para Contato</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_contact"
              autofocus
              holder-id="codex-editor-content-contact"
              save-button-id="save-button-contact"
              :init-data="initDataContact"
              @save="saveContact"
              @ready="onReadyContact"
              @change="onChangeContact"
          />
        </div>
      </div>   
      <div>
        <label>Perguntas Frequentes</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_faq"
              autofocus
              holder-id="codex-editor-content-faq"
              save-button-id="save-button-faq"
              :init-data="initDataFaq"
              @save="saveFaq"
              @ready="onReadyFaq"
              @change="onChangeFaq"
          />
        </div>
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="saving">{{ saving ? 'Salvando...' : 'Salvar' }}</button>
    </div>

    <router-link to="/paginas/editar/405" class="btn"><font-awesome-icon icon="edit"/> Legislação</router-link>
    <router-link to="/noticias-camara/pactoglobal" class="btn"><font-awesome-icon icon="edit"/> Notícias</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'edititem',
  data() {
    return {
      loading: true,
      saving: false,
      deleting: false,
      savingImg: false,
      itemId: this.$route.params.id,
      item: {        
        about: '',
        goals: '',
        activities: '',
        faq: '',
        manager: '',
        holders: '',
        alternate: '',
        creationDate: '',
        contact: '',
      },
      initDataManager: {
        blocks: []
      },
      initDataHolders: {
        blocks: []
      },
      initDataAlternate: {
        blocks: []
      },
      initDataCreationDate: {
        blocks: []
      },
      initDataAbout: {
        blocks: []
      },
      initDataFaq: {
        blocks: []
      },      
      initDataGoals: {
        blocks: []
      },
      initDataContact: {
        blocks: []
      },
      initDataActivities: {
        blocks: []
      }     
    }
  },
  mounted() {
    let self = this;
    axios.get(this.$apiUrl + 'pactoglobal')
            .then(response => {
              console.log(response.data.item);
              self.item = response.data.item;
              self.item.manager = JSON.parse(self.item.manager);
              self.item.holders = JSON.parse(self.item.holders);
              self.item.alternate = JSON.parse(self.item.alternate);
              self.item.creationDate = JSON.parse(self.item.creationDate);
              self.item.about = JSON.parse(self.item.about);             
              self.item.goals = JSON.parse(self.item.goals);
              self.item.activities = JSON.parse(self.item.activities);
              self.item.faq = JSON.parse(self.item.faq);     
              self.item.contact = JSON.parse(self.item.contact); 

              self.initDataManager = self.item.manager;
              self.initDataHolders = self.item.holders;
              self.initDataAlternate = self.item.alternate;
              self.initDataCreationDate = self.item.creationDate;
              self.initDataAbout = self.item.about;
              self.initDataFaq = self.item.faq;
              self.initDataGoals = self.item.goals;
              self.initDataActivities = self.item.activities;   
              self.initDataContact = self.item.contact;
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              // eslint-disable-next-line no-console
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.saving = true;
        let self = this;

        let itemToSave = Object.assign({}, this.item);
        itemToSave.about = JSON.stringify(itemToSave.about);        
        itemToSave.goals = JSON.stringify(itemToSave.goals);
        itemToSave.activities = JSON.stringify(itemToSave.activities);
        itemToSave.faq = JSON.stringify(itemToSave.faq);
        itemToSave.manager = JSON.stringify(itemToSave.manager);
        itemToSave.holders = JSON.stringify(itemToSave.holders);
        itemToSave.alternate = JSON.stringify(itemToSave.alternate);
        itemToSave.creationDate = JSON.stringify(itemToSave.creationDate);
        itemToSave.contact = JSON.stringify(itemToSave.contact);

        axios.put(this.$apiUrl + 'pactoglobal', itemToSave)
                .then(response => {
                  self.saving = false;
                  this.$alert(response.data.message, 'Sucesso', 'success').then(() => {});
                })
                .catch(error => {
                  this.$alert(error.response.data.message, 'Erro', 'error');
                  // eslint-disable-next-line no-console
                  console.log(error.response.data.error);
                  self.saving = false;
                });
      }
    },        
    saveAbout(response) {      
      this.item.about = response;
    },
    onReadyAbout() {
      this.$refs['editor_content_about'].save();
    },
    onChangeAbout() {
      this.$refs['editor_content_about'].save();
    },    

    saveManager(response) {
      this.item.manager = response;
    },
    onReadyManager() {
      this.$refs['editor_content_manager'].save();
    },
    onChangeManager() {
      this.$refs['editor_content_manager'].save();
    },

    saveHolders(response) {
      this.item.holders = response;
    },
    onReadyHolders() {
      this.$refs['editor_content_holders'].save();
    },
    onChangeHolders() {
      this.$refs['editor_content_holders'].save();
    },

    saveAlternate(response) {
      this.item.alternate = response;
    },
    onReadyAlternate() {
      this.$refs['editor_content_alternate'].save();
    },
    onChangeAlternate() {
      this.$refs['editor_content_alternate'].save();
    },

    saveCreationDate(response) {
      this.item.creationDate = response;
    },
    onReadyCreationDate() {
      this.$refs['editor_content_creation_date'].save();
    },
    onChangeCreationDate() {
      this.$refs['editor_content_creation_date'].save();
    },

    saveContact(response) {
      this.item.contact = response;
    },
    onReadyContact() {
      this.$refs['editor_content_contact'].save();
    },
    onChangeContact() {
      this.$refs['editor_content_contact'].save();
    },

    saveGoals(response) {
      this.item.goals = response;
    },
    onReadyGoals() {
      this.$refs['editor_content_goals'].save();
    },
    onChangeGoals() {
      this.$refs['editor_content_goals'].save();
    },

    saveActivities(response) {
      this.item.activities = response;
    },
    onReadyActivities() {
      this.$refs['editor_content_activities'].save();
    },
    onChangeActivities() {
      this.$refs['editor_content_activities'].save();
    },

    saveFaq(response) {
      this.item.faq = response;
    },
    onReadyFaq() {
      this.$refs['editor_content_faq'].save();
    },
    onChangeFaq() {
      this.$refs['editor_content_faq'].save();
    },    
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }
  }

  .delete-btn {
    background-color: #b13336;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;

    &:hover {
      background-color: darken(#b13336, 20%);
    }
  }
</style>
